import axios from 'axios';

import router from '@/router/index';

import Vue from 'vue';

const state = {
    requestType: {},
    requestTypes: [],
    category: {},
    categories: [],
    item: {},
    configuredItem: {
        assignment: {}
    },
    items: [],
    purchaseRequest: {},
    purchaseRequests: {},
};

const getters = {
    getRequestType: (s) => s.requestType,
    getRequestTypes: (s) => s.requestTypes,
    getCategory: (s) => s.category,
    getCategories: (s) => s.categories,
    getItems: (s) => s.items,
    getItem: (s) => s.item,
    getConfiguredItem: (s) => s.configuredItem,
    getConfiguredItemPrice: (s) => {
        const i = s.configuredItem;
        let estPrice = 0;

        if (i.option && i.option.est_price) {
            estPrice = i.option.est_price;
        } else if (i.item && i.item.est_price) {
            estPrice = i.item.est_price;
        }

        estPrice = Number.parseFloat(estPrice);

        if (i.config) {
            for (const prop in i.config) {
                estPrice += Number.parseFloat(i.config[prop]?.price) || 0;

                // add software
                if (prop === 'Software') {
                    for (const sw of i.config['Software']) {
                        estPrice += Number.parseFloat(sw.est_price);
                    }
                }
            }
        }

        if (i.accessories) {
            for (const a in i.accessories) {
                // let price = i.accessories[a].item.est_price || 0;
                let price;
                
                // set to accessory color price if applicable
                if (i.accessories[a].color && i.accessories[a].color[1].price) {
                    price = Number.parseFloat(i.accessories[a].color[1].price);
                } else {
                    price = Number.parseFloat(i.accessories[a].item.est_price_w_reqs || 0);
                }

                // add accessory config price
                if (i.accessories[a].config) {
                    for (let k in i.accessories[a].config) {
                        let acc_config_price = i.accessories[a].config[k].price;
                        if (acc_config_price) {
                            price += Number.parseFloat(acc_config_price);
                        }
                    }
                }

                const qty = i.accessories[a].qty || 0;
                estPrice += (price * qty);
            }
        }
        
        if (i.item?.meta?.applecare && i.item.meta?.applecare.required) {
            estPrice += Number.parseFloat(i.item.meta.applecare.price);
        }
        
        return estPrice;
    },
    getPurchaseRequest: (s) => s.purchaseRequest,
    getPurchaseRequestItemTotal: (s) => {
        let count = 0;

        for (const reqType in s.purchaseRequest) {
            count += s.purchaseRequest[reqType].length
        }

        return count;
    },
    getTotalPrice: (s) => {
        let total = 0.0;
        for (const prType in s.purchaseRequest) {
            const items = s.purchaseRequest[prType] || [];
            total += items.reduce((sum, item) => (sum += item.item.totalPrice), total);
        }
        return total;
    },
    getPurchaseRequestDivisions: (s) => {
        let divisions = [];
        for (const prType in s.purchaseRequest) {
            const items = s.purchaseRequest[prType] || [];
            divisions = divisions.concat(items.map(x => x.assignment && x.assignment.division).filter(x => x))
        }

        return divisions.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);
    },
    getPurchaseRequests: (s) => s.purchaseRequests,
};

const actions = {
    async loadRequestTypes({ commit }) {
        const response = await axios.get('/purchasing/load_request_types/');
        commit('SET_REQUEST_TYPES', response.data);
    },

    async loadCategories({ commit }, payload) {
        let url = `/purchasing/load_categories/${payload.purchaseTypeSlug}/`;

        const response = await axios.get(url);
        commit('SET_REQUEST_TYPE', response.data.purchase_req_type);
        commit('SET_CATEGORIES', response.data.categories);
    },

    async loadCategory({ commit }, payload) {
        commit('SET_CATEGORY', {slug: payload});
    },

    async loadItems({ commit }, payload) {
        commit('SET_ITEMS', []);
        const response = await axios.get(`/purchasing/load_items/${payload.category}/`);
        commit('SET_ITEMS', response.data);
    },

    async loadItem({ commit }, payload) {
        commit('RESET_CONFIGURED_ITEM');
        const response = await axios.get(`/purchasing/load_item/${payload.id}/`);
        if (payload.setCategory) {
            commit('SET_CATEGORY', {...response.data.category, force: true});
        }

        commit('SET_ITEM', response.data);
        commit('SET_CONFIGURED_ITEM', {item: response.data});
    },

    async loadConfiguredItem({ commit }, payload) {
        commit('RESET_CONFIGURED_ITEM');

        let setCategory = {force: true};
        
        if (!payload.item.category) {
            const response = await axios.get(`/purchasing/load_item/${payload.item.pk}/`);
            setCategory = {...setCategory, ...response.data.category};
        } else {
            setCategory = {...setCategory, ...payload.item.category};
        }

        if (!payload.item.related) {
            const response = await axios.get(`/purchasing/load_related_items/${payload.item.pk}/`);

            payload.item = {...payload.item, related: response.data}
        }

        commit('SET_CATEGORY', setCategory)

        commit('SET_ITEM', payload.item);
        commit('SET_CONFIGURED_ITEM', {...payload, force: true});
    },

    resetItem({ commit }) {
        commit('RESET_ITEM');
        commit('RESET_CONFIGURED_ITEM');
    },

    setConfiguredItem({ commit }, payload) {
        commit('SET_CONFIGURED_ITEM', payload);
    },

    removeConfiguredItem({ commit }, payload) {
        commit('REMOVE_CONFIGURED_ITEM', payload);
    },

    setConfiguredItemOption({ commit }, payload) {
        commit('SET_CONFIGURED_ITEM_OPTION', payload);
    },

    async addPurchaseRequest({ commit, state }, payload) {
        await commit('ADD_PURCHASE_REQUEST', payload);
        localStorage.setItem('purchaseRequest', JSON.stringify(state.purchaseRequest));
    },

    async editPurchaseRequest({ commit, state }, payload) {
        await commit('EDIT_PURCHASE_REQUEST', payload);
        localStorage.setItem('purchaseRequest', JSON.stringify(state.purchaseRequest));
        const msg = `Purchase request item successfully updated`;
        // send snack
        commit('setSuccessSnack', msg, { root: true });
    },

    loadCart({ commit, state }) {
        const localCart = localStorage.getItem('purchaseRequest');
        if (!Object.keys(state.purchaseRequest).length && localCart) {
            commit('SET_PURCHASE_REQUEST', JSON.parse(localCart));
        }
    },

    async removeCartItem({ commit }, payload) {
        await commit('REMOVE_CART_ITEM', payload);
        localStorage.setItem('purchaseRequest', JSON.stringify(state.purchaseRequest));
    },

    async submitPurchaseRequest({ state, commit, dispatch }, payload) {
        payload = {...payload, request: {...state.purchaseRequest}};
        const response = await axios.post(`/purchasing/submit/`, payload);

        if (response.data.success) {
            // clear out cart
            dispatch('refreshNav', null, {root: true});
            // router.push({ name: 'purchasingRequests' });
            // commit('setSuccessSnack', 'Purchase request was successfully submitted', {root: true});
            commit('SET_PURCHASE_REQUEST', {});
            localStorage.setItem('purchaseRequest', JSON.stringify({}));
            
            return response.data.tickets
        }
    },

    async loadRequests({ commit }, payload) {
        const response = await axios.post(`/purchasing/requests/`, payload);
        if (response.data.no_purchase_groups) {
            commit('setErrorSnack', 'You are not in any purchase groups!', {root: true});
            router.push({ name: 'home' });
        }
        commit('SET_PURCHASE_REQUESTS', response.data);
    },

    async requestAction({ dispatch }, payload) {
        const response = await axios.post(`/purchasing/request/${payload.pr_pk}/${payload.action}/`, payload);
        if (response.data.success) {
            if (payload.filter) dispatch('loadRequests', {filter: payload.filter});
            if (payload.refreshNav) dispatch('refreshNav', null, {root: true});
        }
    },
};

const mutations = {
    SET_REQUEST_TYPES(state, payload) {
        state.requestTypes = payload;
    },

    SET_REQUEST_TYPE(state, payload) {
        state.requestType = payload;
    },

    SET_CATEGORIES(state, payload) {
        state.categories = payload;
    },

    SET_CATEGORY(state, payload) {
        if (payload.slug && !payload.force) {
            payload = state.categories.find(x => x.slug === payload.slug);
        }
        state.category = payload;
    },

    SET_ITEMS(state, payload) {
        state.items = payload;
    },

    SET_ITEM(state, payload) {
        state.item = payload;
    },

    SET_CONFIGURED_ITEM(state, payload) {

        if (payload.force) {
            delete payload.force;
            state.configuredItem = payload;
            return;
        }

        const ci = state.configuredItem;

        if (payload.config) {
            let newPayload = ci.config || {};
            newPayload[payload.config] = payload.value;
            payload = {config: newPayload};
        } else {
            // check for other merges
            const mergePayloads = ['accessories', 'replacement', 'install'];

            for (const x of mergePayloads) {
                if (payload[x]) {

                    if (!payload.noMerge) {
                        const oldPayload = ci[x] || {};
                        let newPayload = {...oldPayload, ...payload[x]};

                        payload = {[x]: newPayload};
                    } else {
                        delete payload.noMerge;
                    }
                    
                    break;
                }
                
            }
        }

        state.configuredItem = {...ci, ...payload};
    },

    REMOVE_CONFIGURED_ITEM(state, payload) {
        if (state.configuredItem[payload.type] && state.configuredItem[payload.type][payload.item]) {
            Vue.delete(state.configuredItem[payload.type], payload.item);
        }
    },

    SET_CONFIGURED_ITEM_OPTION(state, payload) {
        Vue.set(state.configuredItem, 'option', payload);
    },

    RESET_ITEM(state) {
        state.item = {};
    },

    RESET_CONFIGURED_ITEM(state) {
        state.configuredItem = {};
    },

    ADD_PURCHASE_REQUEST(state, payload) {
        if (!state.purchaseRequest[payload.type.name]) {
            Vue.set(state.purchaseRequest, payload.type.name, []);
        }
        state.purchaseRequest[payload.type.name].push(payload.item);
    },

    EDIT_PURCHASE_REQUEST(state, payload) {
        for (const prType in state.purchaseRequest) {
            const objIndex = state.purchaseRequest[prType].findIndex(x => x.id === payload.item.id);
            if (objIndex > -1) {
                state.purchaseRequest[prType].splice(objIndex, 1, payload.item);
                break;
            }
        }
    },

    SET_PURCHASE_REQUEST(state, payload) {
        state.purchaseRequest = payload;
    },

    REMOVE_CART_ITEM(state, payload) {
        // enumerate categories
        for (const prType in state.purchaseRequest) {
            if (state.purchaseRequest[prType].some(x => x.id === payload)) {
                // remove it
                state.purchaseRequest[prType] = state.purchaseRequest[prType].filter(x => x.id !== payload);
                break;
            }
        }
    },

    SET_PURCHASE_REQUESTS(state, payload) {
        state.purchaseRequests = payload;
    }
    
};

const purchasingModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default purchasingModule;